<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Articles
			</div>
			<V2Button class="page__info--btn" to="/admin/knowledgebase/articles/create">
				Add article
			</V2Button>
		</div>

		<div>
			<table-view
				:table="getTable"
				:pagination="pagination"
				:totalItems="totalItems"
				@search="onSearch"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<script>

	import V2Button           from '@/components/v2/ui/V2Button';
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Articles'
		},
		components: {
			V2Button,
			TableView
		},
		data: () => ({
			articles: [],
			searchString: '',
			pagination: {
				perPage: 20,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		computed: {
			getTable () {
				return {
					headings: [
						'Title'
					],
					rows: this.articles.map((article) => {
						return {
							route: `/admin/knowledgebase/articles/${article.id}`,
							columns: [
								article.title
							]
						};
					})
				};
			},
			getHeaderAction () {
				return {
					text: 'Add article',
					route: '/admin/knowledgebase/articles/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/knowledgebase',
						text: 'Knowledgebase'
					},
					{
						path: '/admin/knowledgebase/articles',
						text: 'Articles'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setArticles();
		},
		methods: {
			async setArticles () {
				const response = await api.admin.getAllKnowledgebaseArticles({
					include: 'metadata',
					searchString: this.searchString,
					per: this.pagination.perPage,
					page: this.pagination.currentPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems              = response.meta.totalItems;
				this.pagination.totalPages   = response.meta.numPages;
				this.pagination.currentPage  = response.meta.page;
				this.articles                = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			onSearch (value) {
				this.searchString = value;
				this.setArticles();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setArticles();
			}
		}
	};
</script>

<style lang="scss" scoped>

.page {
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      width: fit-content;
    }

    &--btn {
      width: fit-content;
    }
  }
}

</style>
